

















































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  TitleTemplatePublic,
  SelectedCountry,
  RedCapital
} from "@/configuration";
import API from "@/api";
import ProgressBar from "@/components/ProgressBar.vue";

@Component({
  metaInfo() {
    return {
      title:
        this.$data.project != null ? this.$data.project.nombre : "Proyecto",
      titleTemplate: TitleTemplatePublic,
      htmlAttrs: {
        // @ts-ignore
        lang: RedCapital[SelectedCountry].Lang
      }
    };
  },
  data() {
    return {
      RedCapital,
      SelectedCountry,
      project: null
    };
  },
  async beforeMount() {
    this.$data.project = await API.getProject(this.$props.projectId);
  },
  components: {
    ProgressBar
  }
})
export default class ViewProject extends Vue {
  @Prop() projectId!: number;
}
